import React, { useEffect, useState } from 'react'
import Requisicao from '../../helpers/Requisicao';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import LoadingElement from '../../components/LoadingElement';

export default function EditarDadosProcesso({ autenticado, usuario }) {

  const navigate = useNavigate();
  const location = useLocation();

  const { processoId } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingOrgaos, setLoadingOrgaos] = useState(false);
  const [loadingUnidades, setLoadingUnidades] = useState(false);
  const [loadingProcesso, setLoadingProcesso] = useState(false);

  const [processo, setProcesso] = useState(null);

  const [orgaosLista, setOrgaosLista] = useState([]);
  const [orgao, setOrgao] = useState(null);

  const [unidadeLista, setUnidadeLista] = useState([]);
  const [unidadeId, setUnidadeId] = useState(null);
  const [unidadeProcesso, setUnidadeProcesso] = useState({});

  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [volumesProcesso, setVolumesProcesso] = useState("");
  const [objeto, setObjeto] = useState("");

  const [isSEI, setIsSEI] = useState(false);

  useEffect(() => {
    if(autenticado && usuario != null && processo != null){
      if (autenticado && usuario.tipoUsuario !== "ADMINISTRADOR" && usuario.tipoUsuario !== "SCF_ADMINISTRADOR" && 
          !(usuario?.permissoes?.includes("PERM_SCF_EDITAR_PROCESSO") && parseInt(usuario?.unidadeId) === parseInt(processo?.unidade?.id))
      ) {
        alert("Você não possui permissão para alterar os dados do processo.");
        navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
      }
    }
  }, [autenticado, usuario, location, navigate, processo]);

  useEffect(()=>{
    if(!loading){

    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$('#comboboxUnidades').on('change', function (e) {
      setUnidadeId(e.target.value);
    });
    window.$("#comboboxOrgaoSolicitante").on('change', function (e) {
      setOrgao(e.target.value);
    });
  }

    document.title = 'Editar Processo - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')

  }, [loading])

  useEffect(()=>{
    carregarProcessoPorId(processoId);
    carregarListaOrgaos();
    carregarListaUnidades();

    // eslint-disable-next-line
  }, [processoId])

  const setupProcesso = (p) => {
    setOrgao(p?.orgaoSolicitante);
    setUnidadeProcesso(p?.unidade);
    if(p?.numero?.indexOf('SEI-') === 0){
      setIsSEI(true);
      setNumeroProcesso(p?.numero?.substring(4));
    } else {
      setNumeroProcesso(p?.numero);
    }
    setVolumesProcesso(p?.volumeseApensos);
    setObjeto(p?.objeto);

    setProcesso(p);
  }

  const carregarProcessoPorId = (id) => {
    setLoadingProcesso(true);
    Requisicao.getProcessoPorId(id)
      .then(data => {
        setupProcesso(data);
        setLoadingProcesso(false);
      })
      .catch(erro => console.log('erro', erro));
  }

  const carregarListaOrgaos = () => {
    setLoadingOrgaos(true);
    Requisicao.getListaOrgaos()
      .then(data => {
        setOrgaosLista(data);
        setLoadingOrgaos(false);
      })
      .catch(erro => console.log('erro', erro));
  }

  const carregarListaUnidades = () => {
    setLoadingUnidades(true);
    // Requisicao.getListaUnidades()
    Requisicao.getListaUnidadesPorSetor("FISCALIZACAO_E_CONTROLE")
      .then(data => {
        setUnidadeLista(data);
        setLoadingUnidades(false);
      })
      .catch(erro => console.log('erro', erro));
  }

  useEffect(() => {
    if (loadingOrgaos === false &&
      loadingUnidades === false &&
      loadingProcesso === false) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loading, loadingOrgaos, loadingProcesso, loadingUnidades]);

  // preencher comboboxUnidades
  useEffect(() => {
    if(!loading){
      
      if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      window.$("#comboboxUnidades").empty();
      let combobox = document.querySelector("#comboboxUnidades").options;

      combobox.add(new Option("Selecione a Unidade", "-1"));
      unidadeLista.forEach((element) => {
        combobox.add(new Option(element.nome, element.id));
      });

      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (processo != null && processo.unidade != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (parseInt(combobox[i].value) === parseInt(processo.unidade.id)) {
            combobox.selectedIndex = i;
            window.$("#comboboxUnidades").val(processo.unidade.id).trigger("change");
            setUnidadeId(processo.unidade.id);
          }
        }
      }
    }
  }

  }, [loading, unidadeLista, processo, usuario.tipoUsuario]);

  // Atualizar unidadeProcesso sempre que unidadeId muda
  useEffect(() => {
    unidadeLista.forEach((u) => {
      if (parseInt(u.id) === parseInt(unidadeId)) {
        setUnidadeProcesso(u);
      }
    });
  }, [unidadeId, unidadeLista]);

  // preencher comboboxOrgaoSolicitante
  useEffect(() => {
    if(!loading){

    // if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      window.$("#comboboxOrgaoSolicitante").empty();
      let combobox = document.querySelector("#comboboxOrgaoSolicitante").options;

      combobox.add(new Option("Selecione o Órgão Solicitante", "-1"));
      orgaosLista.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (processo != null && processo.orgaoSolicitante != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (combobox[i].value === processo.orgaoSolicitante) {
            combobox.selectedIndex = i;
            // setOrgao(processo.orgaoSolicitante);
            window.$("#comboboxOrgaoSolicitante").val(processo.orgaoSolicitante).trigger("change");
          }
        }
      }
    // }
    }

  }, [loading, orgaosLista, processo, usuario.tipoUsuario]);

  const isAdministrador = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }

  // const hasPermission = () => {
  //   if (usuario?.permissoes?.includes("PERM_SCF_EDITAR_PROCESSO")) {
  //     return true;
  //   }
  //   return false;
  // }


  // // desabilitar e habilitar a combobox de Unidades baseado no tipo de usuario
  // useEffect(() => {
  //   // const tiposUsuarioListaLiberada = ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_ANALISTA_UC", "SCF_REVISOR_UC"];
  //   const comboboxUnidades = document.getElementById("comboboxUnidades");

  //   if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
  //     // console.log(true, usuario?.tipoUsuario);
  //     comboboxUnidades.disabled = false;
  //   } else {
  //     // console.log(false, usuario?.tipoUsuario);
  //     comboboxUnidades.disabled = true;
  //   }
  // }, [usuario, usuario.tipoUsuario]);

  const handleNumeroProcessoChange = (e) => {
    setNumeroProcesso(e.target.value);
  }

  const handleVolumesProcessoChange = (e) => { // ADMIN
    setVolumesProcesso(e.target.value);
  }

  const handleObjetoChange = (e) => {
    setObjeto(e.target.value);
  }

  const limparInterface = () => {
    // window.$("#comboboxOrgaoSolicitante").val("-1").trigger("change");
    // setNumeroProcesso(""); // ADMIN
    // setVolumesProcesso("");
    // setObjeto("");
    // setOrgao("");
    carregarProcessoPorId(processoId);
  }

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (validateForm()) {
      gravar();
    }
  }

  const validateForm = () => {
    // implementar validação dos formulários
    let valido = true;
    let alerta = "Preencha os campos abaixo corretamente:\n";
    if (unidadeProcesso == null || unidadeProcesso?.id == null) {
      valido = false;
      alerta += "Selecione a unidade responsável.\n";
      document.getElementById('comboboxUnidades')?.classList.add("is-invalid");
    } else {
      document.getElementById('comboboxUnidades')?.classList.remove("is-invalid");
    }

    if (orgao == null) {
      valido = false;
      alerta += "Selecione o Órgão Solicitante.\n"
      document.getElementById('comboboxOrgaoSolicitante')?.classList.add("is-invalid");
    } else {
      document.getElementById('comboboxOrgaoSolicitante')?.classList.remove("is-invalid");
    }

    if(volumesProcesso?.length > 254){
      valido = false;
      alerta += 'O campo "Volumes e Apensos do Processo" está excedendo o limite de caracteres. Reduza o tamanho do texto.\n'
    }

    if (!valido) {
      alert(alerta);
    }

    return valido;
  }

  const gravar = () => {
    const dados = {
      "id": processoId,
      "numero": isAdministrador() ? isSEI ? `SEI-${numeroProcesso}` : numeroProcesso : processo.numero,
      "volumeseApensos": volumesProcesso,
      "objeto": objeto,
      "favorecido": null,
      "cnpjFavorecido": null,
      "unidadeId": isAdministrador() ? unidadeProcesso.id : processo.unidade.id,
      "orgaoSolicitante": orgao,
    };

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/processo/editar"

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Falha na edição do Processo.")
        }
        return response.json();
      })
      .then((data) => {
        console.log('data', data);
        // setProcessoSalvo(data);
        alert("Processo editado com sucesso.");
        limparInterface();
        // navigate("/analise/editar/" + data, { state:{referrer:location}, replace:true });
      })
      .catch(erro => console.log(erro));
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Editar Processo</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Editar Processo</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={submitForm}>
          {
            loading ?
            <>
              <div className="card">
                <div className="card-body">
                  <LoadingElement />
                </div>
              </div>
            </> :
            <>
              <div className="card">
                <div className="card-body">
                  {
                    (isAdministrador()) ?
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor='comboboxUnidades'>Unidade de Controle Responsável</label>
                              <select id="comboboxUnidades" className="form-control select2bs4" style={{ width: '100%' }}>
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor='comboboxOrgaoSolicitante'>Órgão Solicitante</label>
                              <select id="comboboxOrgaoSolicitante" className="form-control select2bs4" style={{ width: '100%' }}>
                              </select>
                            </div>
                          </div>
                        </div> */}
                      </> :
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <label>Unidade de Controle Responsável</label>
                            {
                              (processo != null && processo.unidade != null) ?
                                <p>{processo.unidade.nome}</p> :
                                <></>
                            }
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-12">
                            <label>Órgão Solicitante</label>
                            {
                              (processo != null && processo.orgaoSolicitante != null) ?
                                <p>{EnumUtils.getNomeEnum(processo.orgaoSolicitante, orgaosLista)}</p> :
                                <></>
                            }
                          </div>
                        </div> */}
                      </>
                  }
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor='comboboxOrgaoSolicitante'>Órgão Solicitante</label>
                        <select id="comboboxOrgaoSolicitante" className="form-control select2bs4" style={{ width: '100%' }}>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {
                      (isAdministrador()) ?
                        <>
                          <div className="row col-6 p-0 m-0">
                            <div className="col-2" style={{maxWidth: "45px"}}>
                              <p className='text-bold'>SEI?</p>
                              <input type='checkbox' id='checkboxSEI' className='aumentar-scale ml-1' checked={isSEI} onChange={(e) => setIsSEI(e.target.checked)}></input>
                            </div>
                            <div className="col-10">
                              <label htmlFor="inputNumeroProcesso">Número do Processo</label>
                              <div className='input-group'>
                                {
                                  isSEI ?
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="sei-checkbox-addon">SEI-</span>
                                    </div> : <></>
                                }
                                <input type="text" className="form-control" id="inputNumeroProcesso" placeholder="Digite o número do processo"
                                pattern='^[0-9]+/[0-9]+$' autoComplete='off' onChange={handleNumeroProcessoChange} value={numeroProcesso} required />
                              </div>
                            </div>
                          </div>
                        </> :
                        <>
                          <div className="col-6">
                            <label>Número do Processo</label>
                            <p>{processo?.numero}</p>
                          </div>
                        </>
                    }
                    <div className="col-6">
                      <label htmlFor="inputVolumesProcesso">Volumes e Apensos do Processo</label>
                      <input type="text" className="form-control" id="inputVolumesProcesso" placeholder="Digite os volumes do processo" onChange={handleVolumesProcessoChange} value={volumesProcesso} required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputDescricao">Descrição do Objeto</label>
                    <textarea id="inputDescricao" className="form-control" rows={4} placeholder="Descreva o objeto conforme descrito no item 1 do Termo de Referência." onChange={handleObjetoChange} value={objeto} required />
                  </div>

                </div>
                <div className="d-flex flex-row justify-content-end px-3 pb-3">
                  <button type="button" className="btn btn-primary mx-2" onClick={limparInterface}>Limpar</button>
                  <button type="submit" className="btn btn-primary">Salvar</button>
                </div>
              </div>
            </>
          }
        </form>
      </div>

    </>
  )
}
