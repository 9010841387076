import React, { useState, useEffect, useRef } from 'react';
import Autenticar from '../../login/Autenticar';
import LoadingElement from '../../components/LoadingElement';
import { Link } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';

export default function BancoDeRecomendacoes({ usuario, autenticado }) {
  const [titulos, setTitulos] = useState([]);
  const [listaLeiLicitacao, setListaLeiLicitacao] = useState([]);
  const [listaTipoAnalise, setListaTipoAnalise] = useState([]);


  const [searchText, setSearchText] = useState('');
  const [selectedTitulo, setSelectedTitulo] = useState('');
  const [leiLicitacao, setLeiLicitacao] = useState('');
  const [tipoAnalise, setTipoAnalise] = useState('');

  const [pageSize, setPageSize] = useState("5");
  const [page, setPage] = useState(0);

  const [resultado, setResultado] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [modo, setModo] = useState('geral')

  const modoAtual = useRef('geral');


  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });

    window.$(`#comboboxTitulos`).on('change', function (e) {
      setSelectedTitulo(e.target.value);
    });
    window.$(`#comboboxLeiLicitacao`).on('change', function (e) {
      setLeiLicitacao(e.target.value);
    });
    window.$(`#comboboxTipoAnalise`).on('change', function (e) {
      setTipoAnalise(e.target.value);
    });


    carregarLeis();
    carregarTipos();

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    fetch(`${window.servidor}/v1/protegido/modelosanalise/dados/titulosmodelosblocos`, requestOptions)
      .then(response => response.json())
      .then(data => setTitulos(data))
      .catch(error => console.error('Erro carregando titulos:', error));

    pesquisar(0, 5, '', '', '', '', 'geral');
    // eslint-disable-next-line
  }, []);

  const pesquisar = (page, pageSize, selectedTitulo = '', searchText = '', leiLicitacao = '', tipoAnalise = '', modo = 'geral') => {
    setIsLoading(true);
    setResultado(null);

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    // console.log('url', `${window.servidor}/v1/protegido/bancorecomendacao/pesquisa?p=${page}&s=${pageSize}&tituloBloco=${selectedTitulo}&texto=${searchText}&leiLicitacao=${leiLicitacao}&tipoAnalise=${tipoAnalise}&modo=${modo}`)

    fetch(`${window.servidor}/v1/protegido/bancorecomendacao/pesquisa?p=${page}&s=${pageSize}&tituloBloco=${selectedTitulo}&texto=${searchText}&leiLicitacao=${leiLicitacao}&tipoAnalise=${tipoAnalise}&modo=${modo}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setResultado(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Erro na pesquisa:', error);
        setIsLoading(false);
      });
  };

  const carregarLeis = () => {
    Requisicao.getListaLeisLicitacao()
      .then((data) => {
        setListaLeiLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarTipos = () => {
    Requisicao.getListaTiposAnaliseOrigem()
      .then((data) => {
        setListaTipoAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  const isAdministradorOuAdministradorSCF = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }
  const isRevisor = () => {
    if (usuario?.tipoUsuario === "SCF_REVISOR_UC" || usuario?.tipoUsuario === "SCF_REVISOR_UDCI") {
      return true;
    }
    return false;
  }

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []

    if (parseInt(resultado.numeroPagina) === 0) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Primeira</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(0)}>Primeira</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) - 1)}>Anterior</button></li>);
    }

    // for
    if (totalPaginas >= 9) {
      if (parseInt(resultado.numeroPagina) >= 5) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }

      for (let i = Math.max(parseInt(resultado.numeroPagina) - 4, 0); i < parseInt(resultado.numeroPagina); i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado.numeroPagina))}>{parseInt(resultado.numeroPagina) + 1}</button></li>);

      for (let i = parseInt(resultado.numeroPagina) + 1; i <= parseInt(resultado.numeroPagina) + 4 && i < totalPaginas; i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      if ((totalPaginas - parseInt(resultado.numeroPagina)) >= 6) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }
    } else {
      for (let i = 0; i < totalPaginas; i++) {
        if (i === parseInt(resultado.numeroPagina)) {
          pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        } else {
          pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        }
      }
    }

    if (parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas)) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Última</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) + 1)}>Próxima</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.totalPaginas) - 1)}>Última</button></li>);
    }

    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    pesquisar(page, e.target.value, selectedTitulo, searchText, leiLicitacao, tipoAnalise, modo);
  }

  const handleChangePage = (page) => {
    setPage(page);
    pesquisar(page, pageSize, selectedTitulo, searchText, leiLicitacao, tipoAnalise, modo);
  }

  const handleChangeModo = (modo) => {
    setModo(modo);

    // pesquisar();
  }

  const excluir = (id) => {
    if (window.confirm("Tem certeza que deseja excluir a recomendação secionada do banco?\nEsta operação não pode ser desfeita.") === true) {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      fetch(`${window.servidor}/v1/protegido/bancorecomendacao/excluir/${id}`, requestOptions)
        .then(response => {
          if (response.ok) {
            window.alert("Excluído com sucesso.");
            pesquisar(page, pageSize, selectedTitulo, searchText, leiLicitacao, tipoAnalise, modo);
          }
        })
        .catch(error => console.error('Erro carregando titulos:', error));
    }
  }

  useEffect(() => {
    if (modo !== modoAtual.current) {
      modoAtual.current = modo;
      setPage(0);

      pesquisar(0, pageSize, selectedTitulo, searchText, leiLicitacao, tipoAnalise, modo);
    }
  }, [modo, pageSize, selectedTitulo, searchText, leiLicitacao, tipoAnalise])

  // const handleTituloChange = (e) => {
  //   setSelectedTitulo(e.target.value);
  // };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchClick = () => {
    pesquisar(page, pageSize, selectedTitulo, searchText, leiLicitacao, tipoAnalise, modo);
  };

  const containsOption = (options, value) => {
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].value === value) {
        return true;
      }
    }
    return false;
  }


  // preencher comboboxTitulos
  useEffect(() => {
    if (document.querySelector(`#comboboxTitulos`)) {
      window.$(`#comboboxTitulos`).empty();
      let combobox = document.querySelector(`#comboboxTitulos`).options;

      let lei = leiLicitacao != null && leiLicitacao !== "" & leiLicitacao !== "-1" ? leiLicitacao : null;
      let tipoA = tipoAnalise != null && tipoAnalise !== "" & tipoAnalise !== "-1" ? tipoAnalise : null;

      combobox.add(new Option("Todos", ""));
      titulos?.forEach((element) => {
        if (lei != null && tipoA != null) {
          if (element.leiLicitacao === lei && element.tipoAnalise === tipoA && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei != null && tipoA == null) {
          if (element.leiLicitacao === lei && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei == null && tipoA != null) {
          if (element.tipoAnalise === tipoA && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei == null && tipoA == null && !containsOption(combobox, element.titulo)) {
          combobox.add(new Option(element.titulo, element.titulo));
        }
      });

      // console.log('combobox', combobox)

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      // if (leiLicitacao != null) {
      //   for (let i = 0; i < combobox.length; i++) {
      //     if (leiLicitacao === combobox[i].value) {
      //       window.$(`#comboboxTitulos`).val(combobox[i].value).trigger("change");
      //     }
      //   }
      // }
    }
  }, [titulos, leiLicitacao, tipoAnalise]);

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    if (document.querySelector(`#comboboxLeiLicitacao`)) {
      window.$(`#comboboxLeiLicitacao`).empty();
      let combobox = document.querySelector(`#comboboxLeiLicitacao`).options;

      combobox.add(new Option("Todos", ""));
      listaLeiLicitacao?.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;
    }
  }, [listaLeiLicitacao]);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    if (document.querySelector(`#comboboxTipoAnalise`)) {

      window.$("#comboboxTipoAnalise").empty();
      let combobox = document.querySelector("#comboboxTipoAnalise").options;

      combobox.add(new Option("Todos", ""));
      listaTipoAnalise?.forEach((element) => {
        if (usuario?.tipoUsuario.toString() === "ADMINISTRADOR" ||
          usuario?.tipoUsuario.toString() === "SCF_ADMINISTRADOR" ||
          usuario?.tipoUsuario.toString() === "SCF_ANALISTA_UC" ||
          usuario?.tipoUsuario.toString() === "SCF_REVISOR_UC") {
          combobox.add(new Option(element.nome, element.value));
        } else {
          if (element.value !== "ANALISE_PREVIA") {
            combobox.add(new Option(element.nome, element.value));
          }
        }
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;
    }
  }, [listaTipoAnalise, usuario.tipoUsuario]);

  const limparInterface = () => {
    console.log("Limpando Interface.")
    setLeiLicitacao("");
    setTipoAnalise("");
    setSelectedTitulo("");
    setSearchText("");
    window.$("#comboboxLeiLicitacao").val("").trigger("change");
    window.$("#comboboxTipoAnalise").val("").trigger("change");
    window.$("#comboboxTitulos").val("").trigger("change");
  }


  return (
    <>
      <div className="modal fade" id="changePageModal" tabIndex={-1} aria-labelledby="changePageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <form className="modal-content" onSubmit={(e) => {
            e.preventDefault();
            let page = parseInt(document.getElementById("changePageModalInput").value);
            if (page > 0 && page <= parseInt(resultado?.totalPaginas)) {
              handleChangePage(page - 1);
              // document.getElementById("changePageModalInput").value = "";
              window.$("#changePageModal").modal("hide");
            }
          }}>
            <div className="modal-header">
              <h5 className="modal-title" id="changePageModalLabel">Insira o nº da página</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input className="form-control" id="changePageModalInput" type="number" onWheel={(e) => e.target.blur()} placeholder={`1 - ${resultado?.totalPaginas}`} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="submit" className="btn btn-primary">Ir</button>
            </div>
          </form>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Banco de Recomendações</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Banco de Recomendações</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className='d-flex flex-row justify-content-center mx-2'>
                {/* <h4 className='my-3'>Banco de Recomendações:&nbsp;</h4> */}
                <div className="btn-group" role="group">
                  <button type="button" className={modo === 'geral' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => handleChangeModo('geral')}>Todas</button>
                  <button type="button" className={modo === 'individual' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => handleChangeModo('individual')}>Individual</button>
                  <button type="button" className={modo === 'unidade' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => handleChangeModo('unidade')}>Unidade</button>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row my-3'>
                <div className='col col-lg-4'>
                  <label htmlFor="comboboxLeiLicitacao">Lei de Licitações/Serviços</label>
                  <select id="comboboxLeiLicitacao" className="select2bs4" style={{ width: '100%' }}></select>
                </div>
                <div className='col col-lg-4'>
                  <label htmlFor="comboboxTipoAnalise">Tipo da Análise</label>
                  <select id="comboboxTipoAnalise" className="select2bs4" style={{ width: '100%' }}></select>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row my-3'>
                <div className='col col-lg-4'>
                  <label htmlFor="comboboxTitulos">Bloco</label>
                  <select id="comboboxTitulos" className="select2bs4" style={{ width: '100%' }}></select>
                </div>
                <div className='col col-lg-6'>
                  <label htmlFor="inputSearchText">Texto</label>
                  <input type="text" id="inputSearchText" className="form-control" value={searchText} onChange={handleSearchTextChange} />
                </div>
                <div className='col col-lg-2'>
                  <label>&nbsp;</label>
                  <div className='d-flex flex-row'>
                    <div className='mx-1'>
                      <button id='searchButton' disabled={isLoading} className="btn btn-primary" onClick={handleSearchClick}>
                        {isLoading ? (<><span className="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span></>) : <i className="fas fa-search"></i>}
                      </button>
                    </div>
                    <div className='mx-1'>
                      <button id='limparButton' className="btn btn-primary" onClick={() => limparInterface()}>Limpar</button>
                    </div>
                    <div className='mx-1'>
                      <Link id='novoButton' to={'/bancoderecomendacoes/novo'} className="btn btn-primary">Novo</Link>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className='my-3'>
                {
                  (resultado != null && resultado.elementos != null && resultado.elementos instanceof Array && resultado.elementos.length > 0) ?
                    <div className='col'>
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Lei de Licitação/Serviços</th>
                            <th>Tipo de Análise</th>
                            <th>Bloco</th>
                            <th>Recomendação</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            resultado.elementos && resultado.elementos.map((elemento) => (
                              <tr key={elemento.id}>
                                <td>{EnumUtils.getNomeEnum(elemento.leiLicitacao, listaLeiLicitacao)}</td>
                                <td>{EnumUtils.getNomeEnum(elemento.tipoAnalise, listaTipoAnalise)}</td>
                                <td>{elemento.tituloBloco}</td>
                                <td>{
                                  (elemento.html != null && elemento.html !== "") ?
                                    <div dangerouslySetInnerHTML={{ __html: elemento.html }}></div> :
                                    elemento.texto
                                }</td>
                                <td>
                                  <div className='d-flex flex-row'>
                                    {
                                      (elemento?.usuarioLogin !== usuario?.login) && !(isAdministradorOuAdministradorSCF() || isRevisor()) ?
                                      <button type='button' className="btn btn-secondary mx-1" disabled>Editar</button> :
                                      <Link to={`/bancoderecomendacoes/editar/${elemento.id}`} className="btn btn-secondary mx-1">Editar</Link>
                                    }
                                    <button type="button" className="btn btn-danger mx-1" onClick={() => excluir(elemento.id)}>Excluir</button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      <div className='px-2 py-2 row justify-content-between'>
                        {
                          resultado?.elementos != null && resultado?.elementos.length > 0 ?
                            <nav aria-label="Page navigation">
                              <ul className="pagination row px-2">
                                {
                                  getPagination().map((pag, index) => {
                                    return <React.Fragment key={index}>
                                      {pag}
                                    </React.Fragment>
                                  })

                                }
                              </ul>
                            </nav>
                            : <div></div>
                        }
                        <select id="comboboxPageSize" className="form-control col-md-1 text-center" value={pageSize} onChange={handleChangePageSize}>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div> :
                    <>
                      {
                        isLoading ? <LoadingElement /> : <p className='pt-2 text-center'>Nenhuma recomendação encontrada</p>
                      }
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
